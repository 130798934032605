<template>
    <div class="container mt-5">
        <lottie :options="defaultOptions" :height="300"/>
        <div class="d-flex flex-column justify-content-center align-items-center mt-5">
            <h2 class="title-26">{{ !mobile ? 'Lo sentimos esta pagina no existe.' : 'Lo sentimos esta pagina no se encuentra disponible.' }}</h2>
            <router-link v-if="!mobile" to="/">
                <main-button :id="'home'" :title="'common.go_home'"/>
            </router-link>
        </div>
    </div>
</template>

<script>
import animationData from '../../assets/animations/404-planet-animation.json';
import Lottie from "vue-lottie";
import MainButton from '../buttons/MainButton.vue';
import { mapGetters } from 'vuex';

export default {
    name: "404",
    data() {
        return {
            defaultOptions: { animationData: animationData, loop: true },
        }
    },
    components:{
        Lottie,
        MainButton
    },
    computed: {
        ...mapGetters({
            mobile: 'common/mobile',
        }),
    }
}
</script>